<template>
  <div>
    <router-view :key="$route.params.site_id" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('sites', ['active_site']),
    loading() {
      if (!this.active_site) return true;
      return this.active_site.id !== this.$route.params.site_id;
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('sites/fetchSite', {
        id: this.$route.params.site_id,
        nocache: true,
      });
      await this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: this.active_site.name,
        to: { name: 'site-info', params: { site_id: this.active_site.id } },
        last: false,
      });
      await this.fetchMenus();
    } catch (err) {
      console.error('site fetch failed', err);
      this.$toast('Fetching Sites failed, trying again');
      this.$store.dispatch('sites/fetchSite', {
        id: this.$route.params.site_id,
        nocache: true,
        multigroupId: this.$route.params.app,
        fetchWithPermissions: true,
      });
    }
  },
  methods: {
    ...mapActions('menus', ['fetchCompanyMenu']),
    fetchMenus() {
      try {
        let siteCompanyIds = new Set();
        for (const location of this.active_site.locations) {
          if (location.brands && location.brands.length) {
            for (const brand of location.brands) {
              if (brand.company) siteCompanyIds.add(brand.company);
            }
          }
        }
        siteCompanyIds = [...siteCompanyIds];
        Promise.all(
          siteCompanyIds.map((companyId) =>
            this.fetchCompanyMenu({ id: companyId, nocache: true }),
          ),
        );
      } catch (err) {
        console.error('menu fetch failed', err);
        this.$toast('Fetching Menus failed');
      }
    },
  },
};
</script>

<style></style>
